















































































































import { Component, Mixins, Prop } from 'vue-property-decorator'
import ImageInput from '../../components/ImageInput.vue'
import AudioInput from '../../components/AudioInput.vue'
import { Locale } from '../../i18n'
import DictionaryCommon from './mixins/DictionaryCommon'
import EditDictionaryToolbar from './EditDictionaryToolbar.vue'

@Component({ components: { ImageInput, AudioInput, EditDictionaryToolbar } })
export default class EditWordDetails extends Mixins(DictionaryCommon) {
  @Prop({ default: 'en_GB' }) readonly locale!: Locale

  clearImage () {
    this.dictionaryInView.image = null
    this.dictionaryInView.new_image = null
  }

  deleteAudio () {
    this.dictionaryInView.audio = null
    this.dictionaryInView.new_audio = null;

    (this.$refs.audioInput as AudioInput).clearDropFile()
  }

  undoAudioChange () {
    this.dictionaryInView.audio = this.cloneDeep(this.origDictionaryInfo.audio)
    this.dictionaryInView.new_audio = undefined;

    (this.$refs.audioInput as AudioInput).clearDropFile()
  }

  undoImageChange () {
    this.dictionaryInView.image = this.cloneDeep(this.origDictionaryInfo.image)
    this.dictionaryInView.new_image = undefined
  }
}
