import { Component, Vue, Prop } from 'vue-property-decorator'
import { UserModel, DictionaryInfo, DictionaryFlag } from '../../../api'

@Component
export default class DictionaryCommon extends Vue {
  /**
   * Original definition. You should never mutate its properties.
   */
  @Prop() protected readonly origDictionaryInfo!: DictionaryInfo & { new_image?: any, new_audio?: any, new_variant_audio?: any }

  /**
   * Original user definition. You should never mutate its properties.
   */
  @Prop() protected readonly origUserDictionaryInfo!: (DictionaryInfo & { new_image?: any, new_audio?: any, new_variant_audio?: any }) | null

  /**
   * Working copy of definition. Share between components and mutate properties as required
   */
  @Prop() protected readonly dictionaryInfo!: DictionaryInfo & { new_image?: any, new_audio?: any, new_variant_audio?: any }

  /**
   * Working copy of user definition. Share between components and mutate properties as required
   */
  @Prop() protected readonly userDictionaryInfo!: (DictionaryInfo & { new_image?: any, new_audio?: any, new_variant_audio?: any }) | null

  /**
   * Function that checks equality between values, capable of considering nested objects. Recommend using lodash's isEqual
   */
  @Prop({ required: true }) readonly isEqual!: (obj: any, obj2: any) => boolean

  /**
   * Function that creates deep copies of an object. Recommend using lodash's cloneDeep
   */
  @Prop({ required: true }) readonly cloneDeep!: <T>(obj: T) => T

  /**
   * Abstracted user state. Pass in the current user from e.g. vuex store
   */
  @Prop({ required: true }) readonly storedUser!: UserModel

  @Prop({ default: false }) protected readonly personalDictionary!: boolean

  protected get dictionaryInView () {
    if (this.personalDictionary) {
      if (!this.userDictionaryInfo) {
        throw new Error('User dictionary info missing')
      }
      return this.userDictionaryInfo
    } else {
      return this.dictionaryInfo
    }
  }

  protected get originalDefinition () {
    if (this.personalDictionary) {
      return this.origUserDictionaryInfo
    } else {
      return this.origDictionaryInfo
    }
  }

  protected get isNewOverride () {
    return this.origUserDictionaryInfo?.id === 0
  }

  protected get isAdd () {
    return this.origDictionaryInfo.id === 0
  }

  protected get canEdit () {
    if (this.personalDictionary) {
      return true
    } else if (this.userDictionaryInfo === null && (this.storedUser.superuser || this.storedUser.dictionary_editor)) {
      return true
    } else {
      return false
    }
  }

  hasRootFieldChanged (field: keyof (DictionaryInfo & { new_image?: any, new_audio?: any, new_variant_audio?: any })) {
    return !this.isEqual(this.dictionaryInfo[field], this.origDictionaryInfo[field])
  }

  hasUserFieldChanged (field: keyof (DictionaryInfo & { new_image?: any, new_audio?: any, new_variant_audio?: any })) {
    if (!this.userDictionaryInfo || !this.origUserDictionaryInfo) {
      return false
    }
    return !this.isEqual(this.userDictionaryInfo[field], this.origUserDictionaryInfo[field])
  }

  hasFieldChanged (field: keyof (DictionaryInfo & { new_image?: any, new_audio?: any, new_variant_audio?: any })) {
    if (this.personalDictionary) {
      return this.hasUserFieldChanged(field)
    } else {
      return this.hasRootFieldChanged(field)
    }
  }

  hasRootFlagChanged (flag: DictionaryFlag) {
    return this.origDictionaryInfo.flags.includes(flag) !== this.dictionaryInView.flags.includes(flag)
  }

  hasUserFlagChanged (flag: DictionaryFlag) {
    if (!this.userDictionaryInfo || !this.origUserDictionaryInfo) {
      return false
    }

    return this.origUserDictionaryInfo.flags.includes(flag) !== this.userDictionaryInfo.flags.includes(flag)
  }

  hasFlagChanged (flag: DictionaryFlag) {
    if (this.personalDictionary) {
      return this.hasUserFlagChanged(flag)
    } else {
      return this.hasRootFlagChanged(flag)
    }
  }

  doesFieldMatchRoot (field: keyof (DictionaryInfo & { new_image?: any, new_audio?: any, new_variant_audio?: any })) {
    if (this.personalDictionary) {
      return this.doesUserFieldMatchRoot(field)
    } else {
      return true
    }
  }

  doesUserFieldMatchRoot (field: keyof (DictionaryInfo & { new_image?: any, new_audio?: any, new_variant_audio?: any })) {
    if (!this.userDictionaryInfo) {
      return false
    }

    return this.isEqual(this.userDictionaryInfo[field], this.dictionaryInfo[field])
  }

  doesFlagMatchRoot (flag: DictionaryFlag) {
    if (this.personalDictionary) {
      return this.doesUserFlagMatchRoot(flag)
    } else {
      return true
    }
  }

  doesUserFlagMatchRoot (flag: DictionaryFlag) {
    if (!this.userDictionaryInfo) {
      return false
    }

    return this.userDictionaryInfo.flags.includes(flag) === this.dictionaryInView.flags.includes(flag)
  }
}
