import { render, staticRenderFns } from "./EditWordDataModal.vue?vue&type=template&id=54dd44da&scoped=true&"
import script from "./EditWordDataModal.vue?vue&type=script&lang=ts&"
export * from "./EditWordDataModal.vue?vue&type=script&lang=ts&"
import style0 from "./EditWordDataModal.vue?vue&type=style&index=0&id=54dd44da&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "54dd44da",
  null
  
)

export default component.exports