



































import { Vue, Component } from 'vue-property-decorator'

@Component({})
export default class IpaKeyboard extends Vue {
  private ipaSymbols: string[] = [
    'æ', 'ɑ', 'ɔ', 'ə', 'ɜ', 'i', 'ɪ', 'e', 'ɛ', 'u', 'ʊ', 'o', 'ʌ', 'ɒ', 'aɪ', 'aʊ',
    'ɔɪ', 'eɪ', 'oʊ', 'iː', 'ɪə', 'eə', 'ʊə', 'ɪŋ', 'aɪə', 'aʊə', 'ɔɪə', 'eɪə', 'oʊə', 'aɪ̯', 'aʊ̯',
    'ɔɪ̯', 'eɪ̯', 'oʊ̯', 'ɪː', 'ʊː', 'uː', 'ɑː', 'ɔː', 'ɜː', 'ər',
    'b', 'd', 'f', 'g', 'h', 'j', 'k', 'l', 'm', 'n', 'ŋ', 'p', 'r', 's', 'ʃ',
    't', 'v', 'w', 'z', 'ʒ', 'ð', 'θ', 'dʒ', 'tʃ'
  ]

  addIpaSymbol (ipa: string) {
    this.$emit('add-ipa', ipa)
  }

  clearIpaField () {
    this.$emit('clear-field')
  }

  closeKeyboard () {
    this.$emit('close-keyboard')
  }
}
