






















































































































































































































































































































































































































































































import { Component, Mixins, Prop } from 'vue-property-decorator'
import { Locale, LocaleData } from '../../i18n'
import { DictionaryDecodabilityFlag, DictionaryVariantType, GraphemePhonemePair, PhonemesInfo } from '../../api'
import { getOrthographicalStructureOfWord, getPhonologicalStructureOfWord } from '../../phonics/index'
import AudioInput from '../../components/AudioInput.vue'
import { symbolForPhoneme } from '../../phonics'
import IpaKeyboard from '../IpaKeyboard.vue'
import EditDictionaryToolbar from './EditDictionaryToolbar.vue'
import DictionaryCommon from './mixins/DictionaryCommon'
import PhonicsBuilder from './PhonicsBuilder.vue'

@Component({ components: { EditDictionaryToolbar, PhonicsBuilder, AudioInput, IpaKeyboard } })
export default class EditWordPhonics extends Mixins(DictionaryCommon) {
  @Prop({ default: undefined }) readonly label!: string
  @Prop({ default: 'en_GB' }) readonly locale!: Locale
  @Prop({ default: [], required: true }) phonemesData!: PhonemesInfo[]

  /**
   * Function that takes a phoneme code and locale and plays the relevant audio
   */
  @Prop({ required: true }) readonly playSoundForPhoneme!: (code: string, locale: LocaleData) => void

  getOrthographicalStructureOfWord = getOrthographicalStructureOfWord
  getPhonologicalStructureOfWord = getPhonologicalStructureOfWord

  DictionaryVariantType = DictionaryVariantType
  variant_pronunciation: boolean = false

  private showKeyboard: boolean = false

  mounted () {
    this.variant_pronunciation = !!(this.dictionaryInView && this.dictionaryInView.variant_type)
  }

  get currentDecodability () {
    if (this.dictionaryInView.flags.includes('Decodable')) {
      return 'Decodable'
    } else if (this.dictionaryInView.flags.includes('Partially Decodable')) {
      return 'Partially Decodable'
    } else if (this.dictionaryInView.flags.includes('Eventually Decodable')) {
      return 'Eventually Decodable'
    }

    return null
  }

  set currentDecodability (dec: DictionaryDecodabilityFlag | null) {
    const decodabilityFlags: string[] = [...DictionaryDecodabilityFlag]
    this.dictionaryInView.flags = this.dictionaryInView.flags.filter(f => !decodabilityFlags.includes(f))

    if (dec) {
      this.dictionaryInView.flags.push(dec)
    }
  }

  get phonicsComplete (): boolean {
    if (this.dictionaryInView === null || this.dictionaryInView.phonics.length === 0) {
      return true
    }

    const pairs = this.dictionaryInView.phonics

    if (pairs.find(p => !p.grapheme || !p.phoneme) !== undefined) {
      return false
    }

    const word = pairs.reduce((word, pair) => {
      const isSplit = word.includes('_')
      return isSplit ? word.replace('_', pair.grapheme) : word + pair.grapheme
    }, '')

    return (word.toLowerCase() === this.dictionaryInView.word.toLowerCase() && word !== '')
  }

  get phonicsVariantComplete (): boolean {
    if (this.dictionaryInView === null || (this.dictionaryInView.variant_phonics && this.dictionaryInView.variant_phonics.length === 0) || !this.dictionaryInView.variant_phonics) {
      return true
    }

    const pairs = this.dictionaryInView.variant_phonics

    if (pairs.find(p => !p.grapheme || !p.phoneme) !== undefined) {
      return false
    }

    const word = pairs.reduce((word, pair) => {
      const isSplit = word.includes('_')
      return isSplit ? word.replace('_', pair.grapheme) : word + pair.grapheme
    }, '')

    return (word.toLowerCase() === this.dictionaryInView.word.toLowerCase() && word !== '')
  }

  autoFill () {
    this.dictionaryInView.phonics = this.dictionaryInView.word.split('').map((l) => {
      const grapheme = l.toLowerCase()
      return { grapheme, phoneme: grapheme === 'c' ? 'k' : grapheme }
    })
  }

  handlePhonicsChanged (phonics: GraphemePhonemePair[]) {
    this.dictionaryInView.phonics = phonics
  }

  toggleVariantPronunciation () {
    this.variant_pronunciation = !this.variant_pronunciation
    if (!this.variant_pronunciation) {
      this.dictionaryInView.variant_type = null
      this.dictionaryInView.variant_phonics = []
      this.dictionaryInView.variant_audio = null
    } else {
      this.dictionaryInView.variant_type = DictionaryVariantType[0]
      this.autoFillVariant()
    }
  }

  // variant pronuncition
  autoFillVariant () {
    if (!this.dictionaryInView.variant_phonics) {
      return
    }
    if (this.dictionaryInView.phonics.length > 0) {
      this.dictionaryInView.variant_phonics = this.dictionaryInView.phonics
    } else {
      this.dictionaryInView.variant_phonics = this.dictionaryInView.word.split('').map((l) => {
        const grapheme = l.toLowerCase()
        return { grapheme, phoneme: grapheme === 'c' ? 'k' : grapheme }
      })
    }
  }

  handleVariantPhonicsChanged (phonics: GraphemePhonemePair[]) {
    this.dictionaryInView.variant_phonics = phonics
  }

  deleteVariantAudio () {
    this.dictionaryInView.variant_audio = null
    this.dictionaryInView.new_variant_audio = null;

    (this.$refs.variantAudioInput as AudioInput).clearDropFile()
  }

  undoVariantAudioChange () {
    this.dictionaryInView.variant_audio = this.cloneDeep(this.origDictionaryInfo.variant_audio)
    this.dictionaryInView.new_variant_audio = undefined;

    (this.$refs.variantAudioInput as AudioInput).clearDropFile()
  }

  generateIpaDefinition () {
    if (!this.dictionaryInView.phonics) {
      return
    }
    let ipa_definition = ''
    for (const el of this.dictionaryInView.phonics) {
      const symbol = symbolForPhoneme(el.phoneme, this.phonemesData)
      ipa_definition += symbol
    }
    this.dictionaryInView.ipa_definition = ipa_definition
  }

  addIpaSymbol (ipa: string): void {
    if (this.dictionaryInView.ipa_definition) {
      this.dictionaryInView.ipa_definition += ipa
    } else {
      this.dictionaryInView.ipa_definition = ipa
    }
  }
}
