















































































































































































































































import { Component, Mixins, Watch } from 'vue-property-decorator'
import { Api, DictionaryWordElementInfo, ExternalCurriculumNodeInfo, GraphemePhonemePair } from '../../api'
import DictionaryCommon from './mixins/DictionaryCommon'
import EditDictionaryToolbar from './EditDictionaryToolbar.vue'

interface CurriculumNode extends ExternalCurriculumNodeInfo {
  children?: ExternalCurriculumNodeInfo[]
}

@Component({ components: { EditDictionaryToolbar } })

export default class EditWordElements extends Mixins(DictionaryCommon) {
  record: Partial<DictionaryWordElementInfo> = {
    string: '',
    index: 0,
    rule_id: 0,
    rule_name: ''
  }

  private tableDataKey: number = 0

  private currentPage = 1

  private editingElementIndex: number | null = null
  private editingElement: DictionaryWordElementInfo | null = null

  private curriculum: CurriculumNode | null = null

  private topLevelCurricula: CurriculumNode[] = []

  private selectedNodes: CurriculumNode[] = []
  private curriculumNodesData: Map<number, CurriculumNode[]> = new Map()

  private showRulesModal: boolean = false
  @Watch('showRulesModal')
  onShowRulesModal () {
    if (!this.showRulesModal && !this.record.rule_name) {
      this.curriculum = null
    }
  }

  toastMessage: string = 'Hello world!'
  toastType: string = 'is-info'

  get curriculaId () {
    if (process.env.NODE_ENV === 'production') {
      return 7
    } else {
      return 7 // in development or staging change this value depending on the spelling skills node id in your table
    }
  }

  async mounted () {
    await this.getExternalCurriculumNodes(this.curriculaId)
    const nodes = this.curriculumNodesData.get(this.curriculaId)
    if (nodes) {
      this.topLevelCurricula = this.curriculumNodesData.get(this.curriculaId)!
    }
  }

  async getExternalCurriculumNodes (id: number) {
    try {
      const res = await Api.getNodesForExternalCurriculum(id)
      this.curriculumNodesData.set(id, this.createTree(res))
    } catch (err) {
      this.$buefy.toast.open({
        message: 'Could not retrieve the external curriculum nodes',
        position: 'is-bottom',
        type: 'is-danger',
        container: '.toast-container'
      })
    }
  }

  createTree (nodes: CurriculumNode[], parentId: number | null = null): CurriculumNode[] {
    const result: CurriculumNode[] = []
    for (const node of nodes) {
      if (node.parent_id === parentId) {
        const children = this.createTree(nodes, node.id)
        if (children.length) {
          node.children = children
        }
        result.push(node)
      }
    }
    return result
  }

  handleSelectCurriculum (curriculum: CurriculumNode) {
    this.curriculum = curriculum
    const nodes = curriculum.children
    if (nodes) {
      this.handleClickNodeChildren(curriculum)
      this.showRulesModal = true
      this.record.rule_id = 0
      this.record.rule_name = ''
    }
  }

  handleClickNodeChildren (node: CurriculumNode) {
    this.selectedNodes = node.children!
  }

  handleSelectRule (node: CurriculumNode) {
    if (node.children) {
      return
    }
    this.record.rule_id = node.id
    this.record.rule_name = node.name
    this.showRulesModal = false
  }

  get filteredCurriculumNodes () {
    return this.selectedNodes
  }

  private onLetterSelect () {
    this.getElementFromInput()
  }

  private getElementFromInput () {
    const inputs = Array.from(document.querySelectorAll('.dictionary-edit-elements__main input')) as HTMLInputElement[]
    if (inputs.length) {
      inputs.sort((a, b) => parseInt(a.dataset.index || '0') - parseInt(b.dataset.index || '0'))
      const checked = inputs.filter(x => x.checked)
      if (checked.length) {
        const firstIndex = parseInt(checked[0].dataset.index || '0')
        const lastIndex = parseInt(checked[checked.length - 1].dataset.index || '0')
        const chars = inputs.filter(x => parseInt(x.dataset.index || '0') >= firstIndex && parseInt(x.dataset.index || '0') <= lastIndex)
        this.record.string = chars.map(x => x.checked ? x.dataset.value : '_').join('')
        this.record.index = firstIndex
      } else {
        this.record.string = ''
        this.record.index = 0
      }
    }
  }

  private setInputsFromElement () {
    if (this.record) {
      const inputs = Array.from(document.querySelectorAll('.dictionary-edit-elements__main input')) as HTMLInputElement[]
      if (inputs.length) {
        inputs.forEach((i) => {
          i.checked = false
        })
        for (let i = this.record.index!; i < this.record.string!.length + this.record.index!; i++) {
          if (inputs[i] && this.record.string![i - this.record.index!] !== '_') {
            inputs[i].checked = true
          }
        }
      }
    }
  }

  private onEditElement (element: DictionaryWordElementInfo, index: number) {
    try {
      if (this.checkUnsavedChanges()) {
        this.$buefy.dialog.confirm({
          title: 'Unsaved Changes',
          message: 'You have made changes to this element, do you wish to discard the changes?',
          confirmText: 'Discard',
          type: 'is-danger',
          hasIcon: true,
          onConfirm: () => { this.editElement(element, index) }
        })
      } else {
        this.editElement(element, index)
      }
    } catch (err) {
      this.$buefy.toast.open({
        message: 'Could not edit the element',
        position: 'is-bottom',
        type: 'is-danger',
        container: '.toast-container'
      })
    }
  }

  private editElement (element: DictionaryWordElementInfo, index: number) {
    if (element === this.editingElement) {
      this.editingElement = null
      this.record = {
        string: '',
        index: 0,
        rule_id: 0,
        rule_name: ''
      }
    } else {
      this.editingElement = this.dictionaryInView.elements[index]
      this.record = this.cloneDeep(element)
    }
    this.setInputsFromElement()
    this.scrollToTop()
  }

  private clearRule () {
    this.record.rule_id = 0
    this.record.rule_name = ''
  }

  private onResetFields () {
    if (this.editingElement) {
      this.record = this.cloneDeep(this.editingElement)
    } else {
      this.record = {
        string: '',
        index: 0,
        rule_id: 0,
        rule_name: ''
      }
    }
    this.curriculum = null
    this.setInputsFromElement()
  }

  private onSaveElement () {
    if (!this.record.string) {
      this.$buefy.dialog.alert({
        title: 'Warning',
        message: 'Please select a range of characters in the word.',
        type: 'is-warning',
        hasIcon: true
      })
      return
    }
    if (!this.record.rule_id || !this.record.rule_name) {
      this.$buefy.dialog.alert({
        title: 'Warning',
        message: 'Please select a rule.',
        type: 'is-warning',
        hasIcon: true
      })
      return
    }

    if (this.editingElement !== null) {
      const index = this.dictionaryInView.elements.findIndex(e => e === this.editingElement)
      this.dictionaryInView.elements.splice(index, 1, this.completeElementObject(this.record))
    } else {
      this.dictionaryInView.elements.push(this.completeElementObject(this.record))
    }
    this.record = {
      string: '',
      index: 0,
      rule_id: 0,
      rule_name: ''
    }
    this.curriculum = null
    this.editingElement = null
    this.setInputsFromElement()
    this.scrollToTop()
  }

  private onNewElement () {
    if (this.checkUnsavedChanges()) {
      this.$buefy.dialog.confirm({
        title: 'Unsaved Changes',
        message: 'You have made changes to this element, do you wish to discard the changes?',
        confirmText: 'Discard',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: this.newElement
      })
    } else {
      this.newElement()
    }
  }

  private newElement () {
    this.scrollToTop()
    this.editingElement = null
    this.curriculum = null
    this.record = {
      string: '',
      index: 0,
      rule_id: 0,
      rule_name: ''
    }
    this.setInputsFromElement()
  }

  private deleteElement (index: number) {
    try {
      this.$buefy.dialog.confirm({
        title: 'Delete Element',
        message: 'Delete Element?',
        confirmText: 'Discard',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          const elementToDelete = this.dictionaryInView.elements[index]

          this.dictionaryInView.elements.splice(index, 1)

          if (elementToDelete === this.editingElement) {
            this.record = {
              string: '',
              index: 0,
              rule_id: 0,
              rule_name: ''
            }
            this.setInputsFromElement()
          }
        }
      })
    } catch (err) {
      this.$buefy.toast.open({
        message: 'Could not delete the element',
        position: 'is-bottom',
        type: 'is-danger',
        container: '.toast-container'
      })
    }
  }

  private checkUnsavedChanges () {
    if (!this.record.string && !this.record.rule_id && !this.record.rule_name) {
      return
    }
    if (this.editingElement) {
      if (this.isEqual(this.record, this.editingElement)) {
        return false
      }
    }
    return true
  }

  private completeElementObject (element: Partial<DictionaryWordElementInfo>): DictionaryWordElementInfo {
    return {
      id: element.id === undefined ? -1 : element.id,
      string: element.string || '',
      index: element.index === undefined ? 0 : element.index,
      rule_id: element.rule_id || 0,
      rule_name: element.rule_name || ''
    }
  }

  private scrollToTop () {
    const top = this.$refs.top as HTMLElement
    if (top) {
      top.scrollIntoView(false)
    }
  }

  private generatePhonicsElements () {
    let phonics: GraphemePhonemePair[] = []
    if (this.$store.state.user!.school!.settings.trap_bath && this.dictionaryInView.variant_phonics && this.dictionaryInView.variant_phonics.length > 0) {
      phonics = this.dictionaryInView.variant_phonics
    } else if (this.dictionaryInView.phonics && this.dictionaryInView.phonics.length > 0) {
      phonics = this.dictionaryInView.phonics
    } else {
      return
    }
    const wordRules = this.dictionaryInfo.elements.filter((r: DictionaryWordElementInfo) => r.rule_id).map(e => e.rule_id)
    const phonicsNodes = this.topLevelCurricula.filter(n => n.name === 'Phonics')[0].children
    if (!phonicsNodes) {
      return
    }
    for (let i = 0; i < phonics.length; i++) {
      const string = phonics[i].grapheme
      const rule = phonicsNodes.filter((node: CurriculumNode) => {
        const name = node.name.split('|')[0]
        return name === string
      })

      const index = this.dictionaryInView.word.indexOf(string[0])

      if (rule && rule.length > 0) {
        if (!wordRules.includes(rule[0].id)) {
          const element = {
            id: -1,
            string,
            index,
            rule_id: rule[0].id,
            rule_name: rule[0].name
          }

          this.dictionaryInView.elements.push(element)
        }
      }
    }
  }
}
