




























// @ts-ignore
import { extname } from 'path'
import { Vue, Component, Prop } from 'vue-property-decorator'
import { StoredFile } from '../api'

/** Example usage: <AudioInput :record="myRecord" field="audio" /> */
@Component({ })
export default class AudioInput extends Vue {
  @Prop({ required: true })
  private readonly record!: any

  @Prop({ required: true })
  private readonly field!: string

  @Prop({ required: false })
  private readonly label: string | undefined

  @Prop({ required: false, default: false })
  private readonly disabled!: boolean

  private dropFile: File | null = null

  get audio (): StoredFile | null {
    return this.record[this.field]
  }

  public mounted () {
    this.$watch('dropFile', (file: File) => {
      if (!file) {
        return
      }
      const reader = new FileReader()

      reader.readAsDataURL(file)

      reader.addEventListener('load', () => {
        this.setNewField({
          data: reader.result as string,
          extname: extname(file.name)
        })
      })
    })
  }

  private setNewField (audio: any) {
    this.record[this.field] = null
    const newFieldName = `new_${this.field}`
    this.record[newFieldName] = audio
  }

  private onClear () {
    this.record[this.field] = null
    this.setNewField(null)
  }

  clearDropFile () {
    this.dropFile = null
  }

  private playAudio () {
    if (this.audio && this.audio.filePath) {
      const audio = new Audio(this.audio.filePath)
      audio.play()
    }
  }
}
