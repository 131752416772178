











































































































import { Component, Mixins, Prop } from 'vue-property-decorator'
import { Api, PagedResults, RelatedDictionaryWord } from '../../api'
import DictionaryCommon from './mixins/DictionaryCommon'
import EditDictionaryToolbar from './EditDictionaryToolbar.vue'

@Component({ components: { EditDictionaryToolbar } })
export default class EditWordThesaurus extends Mixins(DictionaryCommon) {
  /**
   * Function that debounces input before executing another function. Recommend using loDash's debounce
   */
  @Prop({ required: true }) readonly debounce!: (func: (...args: any[]) => Promise<any>, delay: number) => any

  private synonymSearch: PagedResults<RelatedDictionaryWord> = { items: [], total: 0 }
  private antonymSearch: PagedResults<RelatedDictionaryWord> = { items: [], total: 0 }

  isSynonymValid (syn: RelatedDictionaryWord) {
    if (this.dictionaryInView.id === syn.id || this.dictionaryInView.parent_word?.id === syn.id) {
      this.$buefy.toast.open({
        duration: 5000,
        message: 'Word cannot be a synonym of itself',
        position: 'is-bottom',
        type: 'is-danger'
      })

      return false
    }

    // false if defined as antonym already
    if (this.dictionaryInView.antonyms.find(s => s.id === syn.id) !== undefined) {
      this.$buefy.toast.open({
        duration: 5000,
        message: 'Cannot be a synonym and an antonym',
        position: 'is-bottom',
        type: 'is-danger'
      })

      return false
    }

    // false if duplicate
    return this.dictionaryInView.synonyms.find(s => s.id === syn.id) === undefined
  }

  isAntonymValid (ant: RelatedDictionaryWord) {
    if (this.dictionaryInView.id === ant.id || this.dictionaryInView.parent_word?.id === ant.id) {
      this.$buefy.toast.open({
        duration: 5000,
        message: 'Word cannot be a synonym of itself',
        position: 'is-bottom',
        type: 'is-danger'
      })

      return false
    }

    // false if defined as synonym already
    if (this.dictionaryInView.synonyms.find(a => a.id === ant.id) !== undefined) {
      this.$buefy.toast.open({
        duration: 5000,
        message: 'Cannot be a synonym and an antonym',
        position: 'is-bottom',
        type: 'is-danger'
      })

      return false
    }

    // false if duplicate
    return this.dictionaryInView.antonyms.find(a => a.id === ant.id) === undefined
  }

  searchSynonyms (word: string) {
    this.synonymSearch = { items: [], total: 0 }

    this.searchSynonymsDebounced(word + '%', this)
  }

  searchAntonyms (word: string) {
    this.antonymSearch = { items: [], total: 0 }

    this.searchAntonymsDebounced(word + '%', this)
  }

  searchSynonymsDebounced = this.debounce(async (search: string, ctx: EditWordThesaurus) => {
    const data = await Api.getDictionaryData({ take: 20, skip: 0, term: search }, { locale: ctx.dictionaryInView.locale, parent_id: null, owner: ['edshed'] })
    ctx.synonymSearch = { total: data.total, items: data.items.map(e => ({ id: e.id, related_to_id: ctx.dictionaryInView.id, word: e.word, definitions: e.definitions })) }
  }, 500)

  searchAntonymsDebounced = this.debounce(async (search: string, ctx: EditWordThesaurus) => {
    const data = await Api.getDictionaryData({ take: 20, skip: 0, term: search }, { locale: ctx.dictionaryInView.locale, parent_id: null, owner: ['edshed'] })
    ctx.antonymSearch = { total: data.total, items: data.items.map(e => ({ id: e.id, related_to_id: ctx.dictionaryInView.id, word: e.word, definitions: e.definitions })) }
  }, 500)
}
