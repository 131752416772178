




































































import { Component, Mixins, Prop } from 'vue-property-decorator'
import { GraphemePhonemePair } from '../../api/types/dictionary'
import { symbolForPhoneme, findPhonemesForGrapheme } from '../../phonics/index'
import { Locale, LocaleData } from '../../i18n'
import { PhonemesInfo } from '../../api'
import DictionaryCommon from './mixins/DictionaryCommon'

@Component({})
export default class PhonicsBuilder extends Mixins(DictionaryCommon) {
  @Prop({ required: true }) word!: string
  @Prop({ default: [], required: true }) phonics!: GraphemePhonemePair[]
  @Prop({ default: [], required: true }) phonemesData!: PhonemesInfo[]
  @Prop({ required: true }) readonly playSoundForPhoneme!: (code: string, locale: LocaleData) => void
  @Prop({ default: 'en_GB' }) readonly locale!: Locale

  symbolForPhoneme = symbolForPhoneme
  findPhonemesForGrapheme = findPhonemesForGrapheme

  // phonics methods
  addBlock () {
    if (!this.phonics) {
      this.phonics = []
    }
    this.phonics.push({ grapheme: '', phoneme: '' })
    this.$emit('phonicsChanged', this.phonics)
  }

  removePhonicIndex (index: number) {
    if (!this.phonics) {
      return
    }
    this.phonics.splice(index, 1)
    this.$emit('phonicsChanged', this.phonics)
  }

  doesPairBreakWord (pair: GraphemePhonemePair) {
    if (!this.phonics) {
      return
    }
    const idx = this.phonics.indexOf(pair)
    const pairs = this.phonics.slice(0, idx + 1)

    const word = pairs.reduce((word, ipair) => {
      const isSplit = word.includes('_')
      return isSplit ? word.replace('_', ipair.grapheme) : word + ipair.grapheme
    }, '')

    // treat split digraph as a wildcard
    if (pair.grapheme.includes('_')) {
      return new RegExp(`^${word.replace('_', '.')}`).exec(this.word) === null
    }

    return this.word.indexOf(word) !== 0
  }

  // computed
  get phonicsComplete (): boolean {
    if (this.word === null || this.phonics.length === 0) {
      return true
    }

    const pairs = this.phonics

    if (pairs.find(p => !p.grapheme || !p.phoneme) !== undefined) {
      return false
    }

    const word = pairs.reduce((word, pair) => {
      const isSplit = word.includes('_')
      return isSplit ? word.replace('_', pair.grapheme) : word + pair.grapheme
    }, '')

    return (word.toLowerCase() === this.word.toLowerCase() && word !== '')
  }
}
