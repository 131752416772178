



































import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class EditDictionaryToolbar extends Vue {
  @Prop({ default: '' }) readonly label!: string
  @Prop({ default: false }) readonly personalDictionary!: boolean
  @Prop({ default: true }) readonly matchesRoot!: boolean
  @Prop({ default: false }) readonly hasChanged!: boolean
  @Prop({ default: null }) readonly addOption!: string | null
  @Prop({ default: false }) readonly disabled!: boolean
}
